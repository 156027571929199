<template>
  <div class="edit-institutional">
    <breadcrumbs/>
    <div class="flex flex-col items-end add-area mt-3">
      <div class="w-full mt-10 lg:pe-3 space-y-3">
        <p class="text-sm font-medium text-slate-700 text-start">Biz Kimiz</p>
        <ckeditor class="w-full" v-model="we" :editor="editor" :config="editorConfig"/>
      </div>
      <div class="w-full mt-10 lg:pe-3 space-y-3">
        <p class="text-sm font-medium text-slate-700 text-start">Misyon</p>
        <ckeditor class="w-full" v-model="mission" :editor="editor" :config="editorConfig"/>
      </div>
      <div class="w-full mt-10 lg:pe-3 space-y-3">
        <p class="text-sm font-medium text-slate-700 text-start">Vizyon</p>
        <ckeditor class="w-full" v-model="vision" :editor="editor" :config="editorConfig"/>
      </div>
      <div class="w-full flex mt-10 lg:pe-3 space-x-3">
        <div class="w-1/2">
          <img class="w-full h-96" :src="image" :alt="image">
        </div>
        <div class="w-1/2">
          <file-input
              label="Resim"
              v-model="image1"
          />
        </div>
      </div>
      <button class="m-3 w-24 h-10 bg-orange-700 hover:bg-orange-600 w-32 text-gray-200 font-semibold rounded" @click="editItem">
        <font-awesome-icon v-if="isSpin" :icon="['fas', 'spinner']" class="fa-spin"/>
        <span v-if="!isSpin">Güncelle</span>
      </button>
    </div>
  </div>
</template>
<script setup>
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import { Ckeditor } from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";
import router from "@/router";
import Swal from 'sweetalert2'
import axios from "axios";
import FileInput from "@/components/Inputs/fileInput.vue";
const editor = ClassicEditor
const editorConfig = {
  toolbar: [
    'heading', 'bold', 'italic', 'blockQuote', 'link',
    'bulletedList', 'numberedList',
  ],

}
const route = useRoute()
const store = useStore();
const isSpin = ref(false)
const we = ref()
const mission = ref()
const vision = ref()
const image = ref()
const image1 = ref()
async function getData() {
  await axios.get(`https://service.bemekelektrik.com/api/v1/institutional/${route.params.id}`,{
    headers: {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then((response) => {
    we.value = response.data.we
    vision.value = response.data.vision
    mission.value = response.data.mission
    image.value = response.data.image
  });
}
async function editItem() {
  isSpin.value = true
  let formData = new FormData();
  formData.append("we", we.value);
  formData.append("mission", mission.value);
  formData.append("vision", vision.value);
  if (image1.value){
    formData.append("image1", image1.value);
  }
  formData.append("_method", "PUT");
  axios.post(`https://service.bemekelektrik.com/api/v1/institutional/${route.params.id}`,formData,{
    headers : {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then(response => {
    Swal.fire({
      icon: 'success',
      text:response.data,
      toast:true,
      position: "top-end",
      timer: 2000,
      timerProgressBar:true,
      showConfirmButton:false
    })
    getData()
    isSpin.value = false
  }).catch(error => {
    Swal.fire({
      icon: 'error',
      text:error.response.data.message || ['Hata Oluştu!'],
      toast:true,
      position: "top-end",
      timer: 2000,
      timerProgressBar:true,
      showConfirmButton:false
    })
    isSpin.value = false
  });
}
onMounted(() => {
  getData();
});
</script>
<style scoped>
</style>