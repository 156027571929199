<template>
  <div v-if="store.state.isAuth" class="topbar h-12 w-full shadow-2xl shadow-blue-900 flex">
    <div class="w-full flex items-center justify-end">
      <font-awesome-icon @click="logout" class="text-gray-300 hover:text-gray-400 p-4 text-xl cursor-pointer" :icon="['fas', 'right-from-bracket']"/>
    </div>
  </div>
</template>
<script setup>
import {useStore} from "vuex";
import router from "@/router";
const store = useStore();
function logout() {
  store.dispatch("logout")
  router.push("/login")
}
</script>
<style scoped>
.topbar{
  background-color: #333;
}
</style>