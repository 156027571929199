<template>
  <div class="edit-sub-category">
    <breadcrumbs/>
    <div class="flex justify-end mt-5">
      <button @click="router.push('/sub-categories')" class="flex justify-center items-center space-x-2 m-3 w-24 h-10 border bg-purple-950 hover:bg-purple-900 text-gray-200 font-semibold rounded">
        <font-awesome-icon :icon="['fa', 'circle-left']" />
        <span>Geri</span>
      </button>
    </div>
    <div class="flex flex-col items-end add-area mt-3">
      <select-input
          class="lg:pe-3"
          :options="options"
          label="Kategori"
          is-required="true"
          v-model="select_category_id"
      />
      <text-input
          class=" lg:pe-3"
          label="Adı"
          is-required="true"
          v-model="category_name"
      />
      <button class="m-3 w-24 h-10 bg-orange-700 hover:bg-orange-600 w-32 text-gray-200 font-semibold rounded" @click="editItem">
        <font-awesome-icon v-if="isSpin" :icon="['fas', 'spinner']" class="fa-spin"/>
        <span v-if="!isSpin">Güncelle</span>
      </button>
    </div>
  </div>
</template>
<script setup>
import {onMounted, ref} from "vue";
import {useStore} from "vuex";
import axios from "axios";
import router from "@/router";
import {useRoute} from "vue-router";
import Swal from 'sweetalert2'
import TextInput from "@/components/Inputs/textInput.vue";
import SelectInput from "@/components/Inputs/selectInput.vue";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";
const route = useRoute()
const store = useStore();
const isSpin = ref(false)
const category_name = ref('')
const select_category_id = ref()
const options = ref()
async function getData() {
  await axios.get(`https://service.bemekelektrik.com/api/v1/sub_categories/${route.params.id}`,{
    headers: {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then((response) => {
    category_name.value = response.data.name
    select_category_id.value = response.data.category_id
  });
  await axios.get("https://service.bemekelektrik.com/api/v1/categories?no_page",{
    headers: {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then((response) => {
    options.value = response.data
  });
}
async function editItem() {
  isSpin.value = true
  let formData = new FormData();
  if (category_name.value) {
    formData.append("name", category_name.value);
  }
  formData.append('category_id', select_category_id.value)
  formData.append("_method", "PUT");
  axios.post(`https://service.bemekelektrik.com/api/v1/sub_categories/${route.params.id}`,formData,{
    headers : {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then(response => {
    Swal.fire({
      icon: 'success',
      text:response.data,
      toast:true,
      position: "top-end",
      timer: 2000,
      timerProgressBar:true,
      showConfirmButton:false
    })
    router.push('/sub-categories')
    isSpin.value = false
  }).catch(error => {
    Swal.fire({
      icon: 'error',
      text:error.response.data.message || ['Hata Oluştu!'],
      toast:true,
      position: "top-end",
      timer: 2000,
      timerProgressBar:true,
      showConfirmButton:false
    })
    isSpin.value = false
  });
}
onMounted(() => {
  getData();
});
</script>
<style scoped>
</style>