<template>
  <div class="show-web-order">
    <breadcrumbs/>
    <div class="flex justify-end mt-5">
      <button @click="router.push('/web-orders')" class="flex justify-center items-center space-x-2 m-3 w-24 h-10 border bg-purple-950 hover:bg-purple-900 text-gray-200 font-semibold rounded">
        <font-awesome-icon :icon="['fa', 'circle-left']" />
        <span>Geri</span>
      </button>
    </div>
    <div class="flex flex-col items-end add-area mt-3">
      <text-input
          v-model="shop_order_id"
          class=" lg:pe-3"
          label="Sipariş Kodu"
          disabled="true"
      />
      <div class="flex flex-col lg:flex-row w-full lg:space-x-2 lg:pe-3">
        <text-input
            v-model="created_at"
            label="Sipariş Tarihi"
            disabled="true"
        />
        <text-input
            v-model="completed_at"
            label="Tamamlanma Tarihi"
            disabled="true"
        />
      </div>
      <div class="flex flex-col lg:flex-row w-full lg:space-x-2 lg:pe-3">
        <text-input
            v-model="type"
            label="Sipariş Tipi"
            disabled="true"
        />
        <text-input
            v-model="payment_type"
            label="Ödeme Tipi"
            disabled="true"
        />
      </div>
      <div class="flex flex-col lg:flex-row w-full lg:space-x-2 lg:pe-3">
        <text-input
            v-model="name"
            label="Ad Soyad"
            disabled="true"
        />
        <text-input
            v-model="email"
            label="Email"
            disabled="true"
        />
      </div>
      <div class="flex flex-col lg:flex-row w-full lg:space-x-2 lg:pe-3">
        <text-input
            v-model="shipping_at"
            label="Kargolama Tarihi"
            disabled="true"
        />
        <text-input
            v-model="phone"
            label="Telefon"
            disabled="true"
        />
      </div>
      <text-input
          v-model="address"
          class=" lg:pe-3"
          label="Adres"
          disabled="true"
      />
      <div class="w-full lg:pe-3 space-y-3">
        <p class="text-sm font-medium text-slate-700 text-start">Sipariş Notu</p>
        <ckeditor class="w-full" v-model="order_desc" disabled :editor="editor" :config="editorConfig"/>
      </div>
      <div class="flex flex-col lg:flex-row w-full lg:space-x-2 lg:pe-3">
        <text-input
            v-model="transfer_price"
            label="Kargo Ücreti"
            disabled="true"
        />
        <text-input
            v-model="price"
            label="Toplam Ücret"
            disabled="true"
        />
      </div>
      <text-input
          v-model="status"
          label="Sipariş Durumu"
          disabled="true"
      />
      <text-input
          v-model="payment_status"
          label="Ödeme Durumu"
          disabled="true"
      />
      <text-input
          v-model="tracking_code"
          label="Kargo Takip Kodu"
          disabled="true"
      />
    </div>
    <div class="flex flex-col w-full my-4 lg:space-x-2 lg:pe-3" v-if="order_item">
      <p class="my-5" v-if="order_item">Sipariş Ürünleri ( {{order_item.length}} )</p>
      <div class="product-main text-left mt-2" v-for="item in order_item">
          <div class="flex flex-col border p-4">
            <p>Ürün Adı: {{item.product[0].name}}</p>
            <p>Ürün Adeti: {{item.piece}}</p>
            <p>Birim Fiyat: {{item.product[0].total_price}} TL</p>
            <p>Toplam Fiyat: {{item.total_price}} TL</p>
          </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {onMounted, ref} from "vue";
import {useStore} from "vuex";
import moment from 'moment';
import axios from "axios";
import router from "@/router";
import {useRoute} from "vue-router";
import Swal from 'sweetalert2'
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";
import TextInput from "@/components/Inputs/textInput.vue";
import { Ckeditor } from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const editor = ClassicEditor
const editorConfig = {
  toolbar: [
    'heading', 'bold', 'italic', 'blockQuote', 'link',
    'bulletedList', 'numberedList',
  ],

}
const route = useRoute()
const store = useStore();
const isSpin = ref(false)
const shop_order_id = ref()
const created_at = ref()
const completed_at = ref()
const shipping_at = ref()
const payment_type = ref()
const type = ref()
const name = ref()
const email = ref()
const phone = ref()
const address = ref()
const price = ref()
const transfer_price = ref()
const status = ref()
const payment_status = ref()
const tracking_code = ref()
const order_desc = ref()
const order_item = ref()
async function getData() {
  await axios.get(`https://service.bemekelektrik.com/api/v1/show_order/${route.params.id}`,{
    headers : {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then(response => {
    shop_order_id.value = response.data.shop_order_id
    payment_type.value = response.data.payment_type
    type.value = response.data.type
    name.value = response.data.user.name
    email.value = response.data.user.email
    phone.value = response.data.user.phone
    address.value = response.data.order_adress.address
    price.value = response.data.price
    transfer_price.value = response.data.transfer_price
    status.value = response.data.status
    payment_status.value = response.data.payment_status
    tracking_code.value = response.data.tracking_code
    order_desc.value = response.data.order_desc
    created_at.value = moment(response.data.created_at).format('DD/MM/YYYY')
    completed_at.value = response.data.completed_at ? moment(response.data.completed_at).format('DD/MM/YYYY') : '';
    shipping_at.value = response.data.shipping_at ? moment(response.data.shipping_at).format('DD/MM/YYYY') : '';
    order_item.value = response.data.order_item
  }).catch(error => {
    Swal.fire({
      icon: 'error',
      text:error.response.data.message || ['Hata Oluştu!'],
      toast:true,
      position: "top-end",
      timer: 2000,
      timerProgressBar:true,
      showConfirmButton:false
    })
    router.push('/web-orders');
  });
}
onMounted(() => {
  getData()
});
</script>
<style scoped>
</style>