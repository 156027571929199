<template>
  <div class="w-full flex flex-col my-2">
    <p v-if="props.label" :class="props.isRequired ? 'after:content-[\'*\'] after:ml-0.5 after:text-red-500' : ''" class="text-sm font-medium text-slate-700 text-start">{{props.label}}</p>
    <input type="text"
           :placeholder="props.placeholder"
           :value="props.modelValue"
           @input="handleInput"
           autocomplete="text"
           class="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
           :disabled="props.disabled"
    >
  </div>
</template>
<script setup>
const props = defineProps({
  modelValue: {
    required: true
  },
  label: {
    type: String,
  },
  placeholder: {
    type: String,
  },
  isRequired: {
  },
  disabled: {
    default: false
  }
})
const emit = defineEmits(['update:modelValue'])
const handleInput = (event) => {
  emit('update:modelValue', event.target.value)
}
</script>
<style scoped>

</style>