<template>
  <div class="show-contact-form">
    <breadcrumbs/>
    <div class="flex justify-end mt-5">
      <button @click="router.push('/contracts')" class="flex justify-center items-center space-x-2 m-3 w-24 h-10 border bg-purple-950 hover:bg-purple-900 text-gray-200 font-semibold rounded">
        <font-awesome-icon :icon="['fa', 'circle-left']" />
        <span>Geri</span>
      </button>
    </div>
    <div class="flex text-left items-end add-area mt-3">
      <div class="lg:px-3" v-html="data.contract"></div>
    </div>
  </div>
</template>
<script setup>
import {onMounted, ref} from "vue";
import {useStore} from "vuex";
import axios from "axios";
import router from "@/router";
import {useRoute} from "vue-router";
import Swal from 'sweetalert2'
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";
const route = useRoute()
const store = useStore();
const isSpin = ref(false)
const data = ref('')

async function getData() {
  await axios.get(`https://service.bemekelektrik.com/api/v1/contracts?slug=${route.params.id}`, {
    headers: {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then((response) => {
    data.value = response.data.data[0]
  });
}
onMounted(() => {
  getData();
});
</script>
<style scoped>
</style>