<template>
  <div class="show-contact-form">
    <breadcrumbs/>
    <div class="flex justify-end mt-5">
      <button @click="router.push('/contact-forms')" class="flex justify-center items-center space-x-2 m-3 w-24 h-10 border bg-purple-950 hover:bg-purple-900 text-gray-200 font-semibold rounded">
        <font-awesome-icon :icon="['fa', 'circle-left']" />
        <span>Geri</span>
      </button>
    </div>
    <div class="flex flex-col items-end add-area mt-3">
      <div class="flex flex-col lg:flex-row w-full lg:space-x-2 lg:pe-3">
        <text-input
            label="Ad Soyad"
            v-model="data.name"
            disabled="true"
        />
        <text-input
            label="Email"
            v-model="data.email"
            disabled="true"
        />
      </div>
      <div class="flex flex-col lg:flex-row w-full lg:space-x-2 lg:pe-3">
        <text-input
            label="Telefon"
            v-model="data.phone"
            disabled="true"
        />
        <text-input
            label="Adres"
            v-model="data.address"
            disabled="true"
        />
      </div>
      <div class="w-full flex flex-col w-full lg:space-x-2 lg:pe-3">
        <p class="text-sm font-medium text-slate-700 text-start">Mesaj</p>
        <textarea disabled v-model="data.message" />
      </div>

    </div>
  </div>
</template>
<script setup>
import {onMounted, ref} from "vue";
import {useStore} from "vuex";
import axios from "axios";
import router from "@/router";
import {useRoute} from "vue-router";
import Swal from 'sweetalert2'
import TextInput from "@/components/Inputs/textInput.vue";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";
const route = useRoute()
const store = useStore();
const isSpin = ref(false)
const data = ref('')

async function getData() {
  await axios.get(`https://service.bemekelektrik.com/api/v1/contact_form/${route.params.id}`, {
    headers: {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then((response) => {
    data.value = response.data
  });
}
onMounted(() => {
  getData();
});
</script>
<style scoped>
</style>