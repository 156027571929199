<template>
  <div class="new-product-image">
    <breadcrumbs/>
    <div class="flex justify-end mt-5">
      <button @click="router.push(`/product-images/${route.params.id}`)" class="flex justify-center items-center space-x-2 m-3 w-24 h-10 border bg-purple-950 hover:bg-purple-900 text-gray-200 font-semibold rounded">
        <font-awesome-icon :icon="['fa', 'circle-left']" />
        <span>Geri</span>
      </button>
    </div>
    <div class="flex flex-col items-end add-area mt-3">
      <file-input
          label="Resim"
          is-required="true"
          v-model="image1"
      />
      <button class="m-3 w-24 h-10 bg-blue-900 hover:bg-blue-800 w-32 text-gray-200 font-semibold rounded" @click="addItem">
        <font-awesome-icon v-if="isSpin" :icon="['fas', 'spinner']" class="fa-spin"/>
        <span v-if="!isSpin">Ekle</span>
      </button>
    </div>
  </div>
</template>
<script setup>
import {ref} from "vue";
import axios from "axios";
import router from "@/router";
import {useStore} from "vuex";
import Swal from 'sweetalert2'
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";
import FileInput from "@/components/Inputs/fileInput.vue";
import {useRoute} from "vue-router";
const store = useStore();
const route = useRoute()
const isSpin = ref(false)
const image1 = ref()
function addItem() {
  isSpin.value = true
  let formData = new FormData();
  formData.append("product_id", route.params.id);
  if (image1.value){
    formData.append("image1", image1.value);
  }
  axios.post('https://service.bemekelektrik.com/api/v1/product_image',formData,{
    headers : {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then(response => {
    Swal.fire({
      icon: 'success',
      text:response.data,
      toast:true,
      position: "top-end",
      timer: 2000,
      timerProgressBar:true,
      showConfirmButton:false
    })
    router.push(`/product-images/${route.params.id}`)
    isSpin.value = false
  }).catch(error => {
    Swal.fire({
      icon: 'error',
      text:error.response.data.message || ['Hata Oluştu!'],
      toast:true,
      position: "top-end",
      timer: 2000,
      timerProgressBar:true,
      showConfirmButton:false
    })
    isSpin.value = false
  });
}
</script>
<style scoped>

</style>