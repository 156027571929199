<template>
  <div class="w-full flex flex-col my-2">
    <p :class="props.isRequired ? 'after:content-[\'*\'] after:ml-0.5 after:text-red-500' : ''" class="text-sm font-medium text-slate-700 text-start">{{props.label}}</p>
    <div class="w-full flex flex-row items-center space-x-2">
      <select
          :value="props.modelValue"
          @input="handleInput"
          autocomplete="select"
          class="w-full bg-white border border-slate-300 rounded-md shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
          :disabled="props.disabled"
      >
        <option v-for="item in props.options" :value="item.id">{{item.name}}</option>
      </select>
      <font-awesome-icon class="cursor-pointer" :icon="['fa', 'trash-can']" @click="delValue"/>
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  modelValue: {
    required: true
  },
  options: {
    required: true,
  },
  label: {
    type: String,
  },
  placeholder: {
    type: String,
  },
  isRequired: {
  },
  disabled: {
    default: false
  }
})
const emit = defineEmits(['update:modelValue'])
const handleInput = (event) => {
  emit('update:modelValue', event.target.value)
}
const delValue = () => {
  if (props.disabled !== true){
    emit('update:modelValue', null)
  }
}
</script>
<style scoped>
select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #ccc;
  background-color: white;
  padding: 8px 12px;
  font-size: 14px;
  background-repeat: no-repeat;
  background-position: right 10px center;
  cursor: pointer;
}

select option {
  background-color: white;
  color: black;
}
</style>