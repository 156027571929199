<template>
  <div class="font-sans flex w-full">
    <sidebar/>
    <div class="w-full space-y-5" :class="store.state.isAuth ? windowWidth <= 1024 ? 'ms-0' : 'ms-2' : 'ms-0' ">
      <topbar/>
      <router-view/>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import Sidebar from "@/components/Sidebar/sidebar.vue";
import Topbar from "@/components/Topbar/topbar.vue";
import {useStore} from "vuex";
const store = useStore();
const token = store.dispatch("refreshToken");
const windowWidth = ref(window.innerWidth);
function handleResize() {
  windowWidth.value = window.innerWidth;
}
onMounted(() => {
  window.addEventListener('resize', handleResize);
});
onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
