<template>
  <div class="breadcrumb">
    <span class="font-medium text-md">{{route.name}}</span>
  </div>
</template>
<script setup>
import {useRoute} from "vue-router";
const route = useRoute()
</script>
<style scoped>

</style>