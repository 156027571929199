<template>
  <div class="w-full flex flex-col my-2">
    <p v-if="props.label" :class="props.isRequired ? 'after:content-[\'*\'] after:ml-0.5 after:text-red-500' : ''" class="text-sm font-medium text-slate-700 text-start">{{props.label}}</p>
    <input type="file" class="mt-2 w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-violet-700 hover:file:bg-violet-100"
      @change="handleInput"
      autocomplete="image"
    />
  </div>
</template>
<script setup>
const props = defineProps({
  modelValue: {
    required: true
  },
  label: {
    type: String,
  },
  isRequired: {
  }
})
const emit = defineEmits(['update:modelValue'])
const handleInput = (event) => {
  const selectedFile = event.target.files[0]; // Seçilen dosyayı al
  if (selectedFile) {
    emit('update:modelValue', selectedFile)
  }
}
</script>
<style scoped>

</style>