<template>
  <div class="edit-contact">
    <breadcrumbs/>
    <div class="flex flex-col items-end add-area mt-3" v-if="data">
      <div class="flex flex-col lg:flex-row w-full lg:space-x-2 lg:pe-3" >
        <text-input
            label="Telefon"
            v-model="data.phone"
        />
        <text-input
            label="Telefon 2"
            v-model="data.phone_two"
        />
      </div>
      <div class="flex flex-col lg:flex-row w-full lg:space-x-2 lg:pe-3" >
        <text-input
            label="Email"
            v-model="data.email"
        />
        <text-input
            label="Adres"
            v-model="data.address"
        />
      </div>
      <div class="flex flex-col lg:flex-row w-full lg:space-x-2 lg:pe-3" >
        <text-input
            label="İnstagram Url"
            v-model="data.instagram_url"
        />
        <text-input
            label="Linkedin Url"
            v-model="data.linkedin_url"
        />
      </div>
      <div class="flex flex-col lg:flex-row w-full lg:space-x-2 lg:pe-3" >
        <text-input
            label="Facebook Url"
            v-model="data.facebook_url"
        />
        <text-input
            label="X Url"
            v-model="data.x_url"
        />
      </div>
      <button class="m-3 w-24 h-10 bg-orange-700 hover:bg-orange-600 w-32 text-gray-200 font-semibold rounded" @click="editItem">
        <font-awesome-icon v-if="isSpin" :icon="['fas', 'spinner']" class="fa-spin"/>
        <span v-if="!isSpin">Güncelle</span>
      </button>
    </div>
  </div>
</template>
<script setup>
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";
import router from "@/router";
import Swal from 'sweetalert2'
import axios from "axios";
import TextInput from "@/components/Inputs/textInput.vue";
const route = useRoute()
const store = useStore();
const isSpin = ref(false)
const data = ref()
async function getData() {
  await axios.get(`https://service.bemekelektrik.com/api/v1/contact/${route.params.id}`,{
    headers: {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then((response) => {
    data.value = response.data
  });
}
async function editItem() {
  isSpin.value = true
  let formData = new FormData();
  formData.append("phone", data.value.phone);
  formData.append("phone_two", data.value.phone_two);
  formData.append("email", data.value.email);
  formData.append("address", data.value.address);
  formData.append("instagram_url", data.value.instagram_url);
  formData.append("linkedin_url", data.value.linkedin_url);
  formData.append("facebook_url", data.value.facebook_url);
  formData.append("x_url", data.value.x_url);
  formData.append("_method", "PUT");
  axios.post(`https://service.bemekelektrik.com/api/v1/contact/${route.params.id}`,formData,{
    headers : {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then(response => {
    Swal.fire({
      icon: 'success',
      text:response.data,
      toast:true,
      position: "top-end",
      timer: 2000,
      timerProgressBar:true,
      showConfirmButton:false
    })
    getData()
    isSpin.value = false
  }).catch(error => {
    Swal.fire({
      icon: 'error',
      text:error.response.data.message || ['Hata Oluştu!'],
      toast:true,
      position: "top-end",
      timer: 2000,
      timerProgressBar:true,
      showConfirmButton:false
    })
    isSpin.value = false
  });
}
onMounted(() => {
  getData();
});
</script>
<style scoped>
</style>