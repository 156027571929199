<template>
  <div v-if="windowWidth >  1024 && store.state.isAuth" class="sidebar max-w-80 min-h-screen shadow-2xl shadow-blue-900 z-50">
    <div class="flex justify-center relative">
      <img class="size-3/4 p-2" src="../../assets/images/teknokoz.png" alt="">
      <font-awesome-icon @click="handleVisibility" v-if="windowWidth <= 1024" :icon="['fas', 'xmark']" class="cursor-pointer text-3xl text-gray-300 absolute top-0 right-0 mr-2" />
    </div>
    <NavItem
      :item="item"
      v-for="item in navItems"
      :key="item.label"/>
  </div>
  <font-awesome-icon @click="handleVisibility" v-if="windowWidth <= 1024 && store.state.isAuth" class="z-50 text-gray-300 hover:text-gray-400 cursor-pointer p-4 text-xl absolute" :icon="['fas', 'bars']"/>
  <div v-if="isVisibility && store.state.isAuth" class="sidebar min-h-full shadow-2xl shadow-blue-900 absolute w-full z-50">
    <div class="flex justify-center relative">
      <img class="size-1/4 p-2" src="../../assets/images/teknokoz.png" alt="">
      <font-awesome-icon @click="handleVisibility" v-if="windowWidth <= 1024" :icon="['fas', 'xmark']" class="cursor-pointer text-3xl text-gray-300 absolute top-0 right-0 mr-2" />
    </div>
    <NavItem
        :item="item"
        v-for="item in navItems"
        :key="item.label"
        @visible="handleVisibility"
    />
  </div>
</template>
<script setup>
import { useStore } from 'vuex';
import NavItem from "../Sidebar/navItem.vue";
import Arrow from '/src/assets/icons/arrow.png'
const store = useStore();
const isVisibility = ref(false)
const navItems = [
  {href:'/',label:'Dashboard',children:[],icon:null},
  {href:'#',label:'Website Yönetim',children:[
      {href:'/home-slider',label:'Anasayfa Slider',children:[],icon:null},
      {href:'/catalog',label:'Katalog',children:[],icon:null},
      {href:'/edit-institutional/1',label:'Kurumsal',children:[],icon:null},
      {href:'/edit-contact/1',label:'İletişim',children:[],icon:null},
      {href:'/ibans',label:'Banka Hesapları',children:[],icon:null},
      {href:'/contracts',label:'Sözleşmeler',children:[],icon:null},
      {href:'/contact-forms',label:'İletişim Formları',children:[],icon:null},
    ],icon:Arrow},
  {href:'#',label:'Ürün Yönetimi',children:[
      {href:'/categories',label:'Kategoriler',children:[],icon:null},
      {href:'/sub-categories',label:'Alt Kategoriler',children:[],icon:null},
      {href:'/brands',label:'Markalar',children:[],icon:null},
      {href:'/taxes',label:'Vergiler',children:[],icon:null},
      {href:'/products',label:'Ürünler',children:[],icon:null},
    ],icon:Arrow},
  {href:'#',label:'Sipariş Yönetimi',children:[
      {href:'/web-orders',label:'Web Siparişler',children:[],icon:null},
      {href:'/categories',label:'İade İşlemleri',children:[],icon:null},
    ],icon:Arrow},
]
import { ref, onMounted, onBeforeUnmount } from 'vue';
const windowWidth = ref(window.innerWidth);
function handleResize() {
  windowWidth.value = window.innerWidth;
}
onMounted(() => {
  window.addEventListener('resize', handleResize);
});
onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
function handleVisibility(){
  isVisibility.value = !isVisibility.value;
}
</script>

<style scoped>
.sidebar{
  background-color: #333;
}
</style>