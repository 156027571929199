<template>
  <div class="login min-h-screen w-full flex justify-center items-center">
    <div class="lg:container lg:w-8/12 w-full lg:min-h-[600px] min-h-screen lg:mx-auto flex flex-row shadow-xl shadow-indigo-800/70">
      <div class="w-1/2 md:flex justify-center align-center hidden">
        <img class="" src="../assets/images/login.jpg" alt="teknokoz-login">
      </div>
      <div class="md:w-1/2 w-full flex flex-col bg-slate-50">
        <div class="flex justify-center align-center mt-16">
          <img class="max-w-52" src="../assets/images/teknokoz.png" alt="">
        </div>
        <div class="flex justify-center align-center mt-10">
          <h1 class="text-2xl font-medium">Control Panel</h1>
        </div>
        <div class=" mt-16 flex justify-center align-center">
          <form class="w-8/12 p-4" @submit.prevent="false">
            <email-input
              v-model="email"
              label="Email"
              placeholder="Enter Email"
              isRequired="true"
            />
            <password-input
              v-model="password"
              label="Password"
              placeholder="Enter Password"
              isRequired="true"
            />
            <div class="flex">
              <div class="w-1/2">
                <button class="mt-6 rounded-lg border w-full min-h-10 bg-slate-700 hover:bg-slate-800 text-sm text-white font-medium">
                  Şifremi Unuttum
                </button>
              </div>
              <div class="w-1/2">
                <button @click="auth" class="mt-6 rounded-lg border w-full min-h-10 bg-blue-700 hover:bg-blue-900 text-sm text-white font-medium" :disabled="isSpin">
                  <font-awesome-icon v-if="isSpin" :icon="['fas', 'spinner']" class="fa-spin"/>
                  <span v-if="!isSpin">Giriş</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {ref} from "vue";
import { useStore } from 'vuex';
import {useRouter} from "vue-router";
import Swal from 'sweetalert2'
import EmailInput from "@/components/Inputs/emailInput.vue";
import PasswordInput from "@/components/Inputs/passwordInput.vue";
const store = useStore();
const router = useRouter()
const email = ref('')
const password = ref('')
const isSpin = ref(false)
async function auth() {
  isSpin.value = true
  if (!email.value || !password.value) {
    Swal.fire({
      icon: 'warning',
      text:'Giriş Bilgileri Eksik!',
      toast:true,
      position: "top-end",
      timer: 2000,
      timerProgressBar:true,
      showConfirmButton:false
    })
    isSpin.value = false
  }else{
    store.dispatch('authenticate', {
      username: email.value,
      password: password.value
    }).then(response => {
      isSpin.value = false
      Swal.fire({
        icon: 'success',
        text:'Giriş Başarılı!',
        toast:true,
        position: "top-end",
        timer: 2000,
        timerProgressBar:true,
        showConfirmButton:false
      })
      router.push('/')
      isSpin.value = false
    }).catch(error => {
      isSpin.value = false
      Swal.fire({
        icon: 'error',
        text:'Giriş Bilgileri Hatalı!',
        toast:true,
        position: "top-end",
        timer: 2000,
        timerProgressBar:true,
        showConfirmButton:false
      })
    })
  }
}
</script>
<style scoped>
.login{
  background-color: #333;
}
</style>