<template>
  <div class="show-product">
    <breadcrumbs/>
    <div class="flex justify-end mt-5">
      <button @click="router.push('/products')" class="flex justify-center items-center space-x-2 m-3 w-24 h-10 border bg-purple-950 hover:bg-purple-900 text-gray-200 font-semibold rounded">
        <font-awesome-icon :icon="['fa', 'circle-left']" />
        <span>Geri</span>
      </button>
      <button @click="router.push(`/product-images/${route.params.id}`)" class="flex justify-center items-center space-x-1 m-3 w-40 h-10 border bg-rose-600 hover:bg-rose-500 text-gray-200 font-semibold rounded">
        <font-awesome-icon :icon="['fa', 'photo-film']" />
        <span>Resim Galerisi</span>
      </button>
    </div>
    <div class="flex flex-col items-end add-area mt-3">
      <text-input
          class=" lg:pe-3"
          label="Ürün Ad"
          is-required="true"
          v-model="product_name"
          disabled="true"
      />
      <div class="flex flex-col lg:flex-row w-full lg:space-x-2 lg:pe-3">
        <select-input
            label="Kategori"
            is-required="true"
            :options="categories"
            v-model="product_category"
            :disabled=true
        />
        <select-input
            label="Alt Kategori"
            is-required="true"
            :options="sub_categories"
            v-model="product_sub_category"
            :disabled=true
        />
      </div>
      <div class="flex flex-col lg:flex-row w-full lg:space-x-2 lg:pe-3">
        <select-input
            label="Marka"
            is-required="true"
            :options="brands"
            v-model="product_brand"
            :disabled=true
        />
        <select-input
            label="Vergi Dilimi"
            is-required="true"
            :options="taxes"
            v-model="product_tax"
            :disabled=true
        />
      </div>
      <div class="flex flex-col lg:flex-row w-full lg:space-x-2 lg:pe-3">
        <text-input
            label="Birim Fiyat"
            v-model="product_unit_price"
            disabled="true"
        />
        <text-input
            label="Kargo Ücreti"
            v-model="product_transfer_fee"
            disabled="true"
        />
      </div>
      <div class="w-full lg:pe-3 space-y-3">
        <p class="text-sm font-medium text-slate-700 text-start">Ürün Açıklaması</p>
        <ckeditor class="w-full" disabled v-model="product_desc" :editor="editor" :config="editorConfig"/>
      </div>
    </div>
  </div>
</template>
<script setup>
import {onMounted, ref} from "vue";
import {useStore} from "vuex";
import axios from "axios";
import router from "@/router";
import {useRoute} from "vue-router";
import Swal from 'sweetalert2'
import TextInput from "@/components/Inputs/textInput.vue";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";
import SelectInput from "@/components/Inputs/selectInput.vue";
import { Ckeditor } from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const editor = ClassicEditor
const editorConfig = {
  toolbar: [
    'heading', 'bold', 'italic', 'blockQuote', 'link',
    'bulletedList', 'numberedList',
  ],

}
const route = useRoute()
const store = useStore();
const isSpin = ref(false)
const product_name = ref()
const product_brand = ref()
const product_tax = ref()
const product_category = ref()
const product_sub_category = ref()
const product_unit_price = ref()
const product_transfer_fee = ref()
const product_desc = ref()
const brands = ref()
const taxes = ref()
const categories = ref()
const sub_categories = ref()
async function getData() {
  await axios.get(`https://service.bemekelektrik.com/api/v1/product/${route.params.id}`,{
    headers : {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then(response => {
    product_name.value = response.data.name
    product_brand.value = response.data.brand_id
    product_tax.value = response.data.tax_id
    product_category.value = response.data.category_id ? response.data.category_id : null
    product_sub_category.value = response.data.sub_category_id ? response.data.sub_category_id : null
    product_unit_price.value = response.data.unit_price
    product_transfer_fee.value = response.data.transfer_fee
    product_desc.value = response.data.desc
  }).catch(error => {
    Swal.fire({
      icon: 'error',
      text:error.response.data.message || ['Hata Oluştu!'],
      toast:true,
      position: "top-end",
      timer: 2000,
      timerProgressBar:true,
      showConfirmButton:false
    })
    router.push('/product');
  });
}
async function getUsedData(){
  await axios.get(`https://service.bemekelektrik.com/api/v1/brand?no_page`,{
    headers: {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then((response) => {
    brands.value = [];
    brands.value = response.data;
  })
  await axios.get(`https://service.bemekelektrik.com/api/v1/tax?no_page`,{
    headers: {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then((response) => {
    taxes.value = []
    taxes.value = response.data
  })
  await axios.get(`https://service.bemekelektrik.com/api/v1/categories?no_page`,{
    headers: {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then((response) => {
    categories.value = []
    categories.value = response.data
  })
  await axios.get(`https://service.bemekelektrik.com/api/v1/sub_categories?no_page`,{
    headers: {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then((response) => {
    sub_categories.value = []
    sub_categories.value = response.data
  })
}
onMounted(() => {
  getData()
  getUsedData()
});
</script>
<style scoped>
</style>