<template>
  <div class="w-full px-3 text-gray-300 font-semibold z-50">
    <router-link class="flex text-md my-2 align-center text-md xl:text-lg" @click="handle" :to="item.href" v-if="!item.children.length">
      <span class="hover:font-bold">{{ item.label }}</span>
    </router-link>
    <Disclosure v-else v-slot="{open}">
      <DisclosureButton class="flex justify-between items-center hover:font-semibold my-2 w-full">
        <span :class="open ? 'text-gray-400':''" class="hover:font-bold text-md xl:text-lg">{{ item.label }}</span>
        <font-awesome-icon :class="open ? 'rotate-180 text-gray-400' : ''" class="text-base" :icon="['fas', 'caret-down']" />
      </DisclosureButton>
      <DisclosurePanel class="ml-4">
        <router-link
            @click="handle"
            class="flex my-1 hover:font-bold text-sm xl:text-base"
            v-for="child in item.children"
            :key="child.label"
            :to="child.href">{{ child.label }}</router-link>
      </DisclosurePanel>
    </Disclosure>
  </div>
</template>
<script setup>
import {Disclosure, DisclosureButton, DisclosurePanel} from "@headlessui/vue";
const props = defineProps({
  item: Object,
})
const emit = defineEmits(["visible"]);
const handle = () => {
  emit('visible', '')
}
</script>
<style scoped>
</style>